import axios from "axios";

export const SERVER_BASE_URL = "https://difaksa-server.onrender.com/api/v1";
// export const SERVER_BASE_URL = "http://localhost:8000/api/v1";

export const login = (email, password) => {
  const response = axios.post(`${SERVER_BASE_URL}/difa-official/login`, {
    email: email,
    password: password,
  });
  return response;
};

export const getRegistrationRequests = async (limit, offset, searchText) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL + `/difa-official/all-player-register-requests`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit,
        offset,
        searchText,
      },
    }
  );
  return response;
};

export const getUserDetails = async () => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL + "/difa-official/get-self-details",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getPlayerRequestByRegisterNumber = async (registerNumber) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL +
      `/difa-official/request-by-register-number/${registerNumber}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const changeUserPassword = async (adminId, password) => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.patch(
      SERVER_BASE_URL + `/difa-official/change-password`,
      { adminId, password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const approvePlayerRequest = async (requestId) => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.patch(
      `${SERVER_BASE_URL}/difa-official/approve-request/${requestId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const withHoldPlayerRequest = async (requestId, remark) => {
  let token = localStorage.getItem("token");

  try {
    const response = await axios.patch(
      `${SERVER_BASE_URL}/difa-official/with-hold-request/${requestId}`,
      { remark },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const resetPlayerRequest = async (requestId) => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.patch(
      `${SERVER_BASE_URL}/difa-official/reset-request/${requestId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getAllTransferRequests = async (limit, offset, searchText) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL + `/difa-official/all-pending-transfer-requests`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit,
        offset,
        searchText,
      },
    }
  );
  return response;
};

export const getTransferById = async (transferId) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL +
      `/difa-official/all-pending-transfer-requests/${transferId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const approveTransferRequest = async (transferId) => {
  let token = localStorage.getItem("token");
  const response = await axios.patch(
    `${SERVER_BASE_URL}/difa-official/approve-transfer/${transferId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export const withHoldTransferRequest = async (requestId) => {
  let token = localStorage.getItem("token");

  const response = await axios.patch(
    `${SERVER_BASE_URL}/difa-official/with-hold-transfer/${requestId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export const resetTransferRequest = async (requestId) => {
  let token = localStorage.getItem("token");

  const response = await axios.patch(
    `${SERVER_BASE_URL}/difa-official/reset-transfer-request/${requestId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export const getAllPlayers = async (limit, offset, searchText, active) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL + `/difa-official/get-all-players`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit,
        offset,
        searchText,
        active,
      },
    }
  );
  return response;
};

export const getAllClubs = async (limit, offset, searchText) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL + `/difa-official/get-all-clubs`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit,
        offset,
        searchText,
      },
    }
  );
  return response;
};

export const getClubById = async (clubId) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(SERVER_BASE_URL + `/difa-official/club`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      clubId,
    },
  });
  return response;
};

export const getPlayersByClub = async (clubId) => {
  let token = localStorage.getItem("token");
  const response = await axios.get(
    SERVER_BASE_URL + `/difa-official/club/${clubId}/players`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const updatePlayer = async (playerId, body) => {
  let token = localStorage.getItem("token");

  const response = await axios.patch(
    `${SERVER_BASE_URL}/difa-official/edit-player/${playerId}`,
    { ...body },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export const uploadPlayerIqama = async (file, s3Id) => {
  let token = localStorage.getItem("token");
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      SERVER_BASE_URL + `/difa-official/player/upload-iqama/` + s3Id,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const uploadPlayerPassport = async (file, s3Id) => {
  let token = localStorage.getItem("token");
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      SERVER_BASE_URL + `/difa-official/player/upload-passport/` + s3Id,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const uploadPlayerRegistrationForm = async (file, s3Id) => {
  let token = localStorage.getItem("token");
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      SERVER_BASE_URL +
        `/difa-official/player/upload-registration-form/` +
        s3Id,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const uploadPlayerAffidavit = async (file, s3Id) => {
  let token = localStorage.getItem("token");
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      SERVER_BASE_URL + `/difa-official/player/upload-affidavit/` + s3Id,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const uploadPlayerImage = async (file, s3Id) => {
  let token = localStorage.getItem("token");
  try {
    const formData = new FormData();
    formData.append("photo", file);

    const response = await axios.post(
      SERVER_BASE_URL + `/difa-official/player/upload-photo/${s3Id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const exportAllPlayers = async () => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      SERVER_BASE_URL + "/difa-official/export-players",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const exportClubPlayers = async (clubId) => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      SERVER_BASE_URL + "/difa-official/export-players",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clubId,
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const getCurrentDocumentsConfig = async () => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      SERVER_BASE_URL + `/difa-official/documents-config`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDocumentsConfig = async (config) => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.patch(
      SERVER_BASE_URL + `/difa-official/save-documents-config`,
      { ...config },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const changePlayerActiveState = async (playerId) => {
  let token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      SERVER_BASE_URL + `/difa-official/player/set-isActive/${playerId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
