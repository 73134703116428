import { LeftCircleOutlined } from "@ant-design/icons";
import { Card, message, Spin, Image, Button, Table, Avatar, Tag } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  exportClubPlayers,
  getClubById,
  getPlayersByClub,
  SERVER_BASE_URL,
} from "../api/difaOfficialApis";
import { UserContext } from "../App";
import avatar from "../assets/avatar.png";
import { saveAs } from "file-saver";
import { Workbook } from "exceljs";

export const IndividualClub = () => {
  const { clubId } = useParams();

  const [club, setClub] = useState();

  const { difaOfficial } = useContext(UserContext);

  let navigate = useNavigate();

  const [playersList, setPlayersList] = useState();
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  async function loadClub() {
    setLoading(true);
    getClubById(clubId)
      .then((res) => {
        if (res.status === 200) {
          setClub(res.data.club);
          getPlayersByClub(clubId).then((res) => {
            let rows = [];
            res.data.players.rows.map((item) => {
              const player = {
                key: item.playerId,
                regno: item.registrationNumber,
                name: (
                  <>
                    <Avatar
                      src={
                        item.photo
                          ? SERVER_BASE_URL +
                            `/get-player-image?key=${item.photo}`
                          : avatar
                      }
                    />
                    <label style={{ paddingLeft: "15px" }}>
                      {item.fullName}
                    </label>
                  </>
                ),
                email: item.email || "-",
                status: getPlayerStatus(item.status),
                createdAt: new Date(item.createdAt).toLocaleDateString(),
              };
              return rows.push(player);
            });
            setPlayersList([...rows]);
          });
        }
      })
      .catch((err) => {
        message.error("Could not connect with the server. Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function handleExport() {
    setExporting(true);
    try {
      const response = await exportClubPlayers(clubId);
      const players = response.data.players;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(`Players-${clubId}`);

      worksheet.columns = [
        { header: "Player ID", key: "playerId", width: 10 },
        { header: "Club ID", key: "clubId", width: 10 },
        { header: "Reg Number", key: "registrationNumber", width: 10 },
        { header: "Full Name", key: "fullName", width: 32 },
        { header: "Email", key: "email", width: 32 },
        { header: "Mobile Number", key: "phone", width: 32 },
        { header: "Date of Birth", key: "dateOfBirth", width: 15 },
        { header: "Blood Group", key: "bloodGroup", width: 15 },
        { header: "Home Address (India)", key: "homeAddress", width: 40 },
        { header: "Saudi Address", key: "homeAddress", width: 40 },
        { header: "Company Name", key: "companyName", width: 12 },
        { header: "Passport Number", key: "passportNumber", width: 12 },
        { header: "Iqama Number", key: "iqamaNumber", width: 12 },
        { header: "Is Active", key: "isActive", width: 12 },
      ];

      worksheet.addRows(players.rows);

      const buf = await workbook.xlsx.writeBuffer();

      saveAs(new Blob([buf]), `Players-${clubId}.xlsx`);
    } catch (err) {
      message.error("Could not connect with the server. Please try again.");
      console.error(err);
    } finally {
      setExporting(false);
    }
  }

  const getPlayerStatus = (status) => {
    const STATUSES = {
      0: "Pending",
      1: "On Hold",
      2: "Approved",
      3: "Verified",
    };

    const statusColors = {
      Verified: "blue",
      Pending: "gray",
      Approved: "green",
      "On Hold": "red",
    };

    return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
  };

  const getIndividualPlayerDetails = (id) => {
    navigate(`/all-players/${id}`, { state: id });
  };

  useEffect(() => {
    loadClub();
  }, [difaOfficial]);

  return club ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "70%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            gap: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              paddingBottom: "18px",
              flex: 0.01,
            }}
          >
            <Button
              icon={<LeftCircleOutlined />}
              style={{
                marginTop: "20px",
                border: "2px solid black",
                backgroundColor: "transparent",
                color: "black",
              }}
              onClick={() => {
                navigate("/clubs", { replace: true });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              flex: 0.99,
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                marginTop: "2%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              CLUB DETAILS
            </h1>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            gap: "2em",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "2em",
          }}
        >
          <Card>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "10%", height: "10%" }}>
                <Image
                  src={
                    club.logo
                      ? SERVER_BASE_URL + `/get-club-logo?key=${club.logo}`
                      : avatar
                  }
                  width={"100%"}
                />
              </div>
              <h2 style={{ paddingTop: "15px" }}>
                <b>{club.name}</b>
              </h2>
            </div>
            <div
              style={{
                paddingTop: "15px",
                display: "flex",
                flexDirection: "row",
                gap: "1em",
              }}
            >
              <Card title={"Email"} style={{ width: "50%" }}>
                <div>
                  <b>{club.email || "-"}</b>
                </div>
              </Card>
              <Card title={"Mobile Number"} style={{ width: "50%" }}>
                <div>
                  <b>{club.phone || "-"}</b>
                </div>
              </Card>
            </div>
            <div
              style={{
                paddingTop: "1em",
                display: "flex",
                flexDirection: "row",
                gap: "1em",
              }}
            >
              <Card title={"Chairman"} style={{ width: "50%" }}>
                <div>
                  <b>{club.chairman || "-"}</b>
                </div>
              </Card>
              <Card title={"President"} style={{ width: "50%" }}>
                <div>
                  <b>{club.president || "-"}</b>
                </div>
              </Card>
            </div>
            <div
              style={{
                paddingTop: "1em",
                display: "flex",
                flexDirection: "row",
                gap: "1em",
              }}
            >
              <Card title={"General Secretary"} style={{ width: "50%" }}>
                <div>
                  <b>{club.generalSecretary || "-"}</b>
                </div>
              </Card>
              <Card title={"Treasurer"} style={{ width: "50%" }}>
                <div>
                  <b>{club.treasurer || "-"}</b>
                </div>
              </Card>
              <Card title={"Team Manager"} style={{ width: "50%" }}>
                <div>
                  <b>{club.teamManager || "-"}</b>
                </div>
              </Card>
            </div>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                display: "flex",
                justifyContent: "center",
              }}
            >
              PLAYERS ({playersList && playersList.length})
            </h1>
            <Button
              type="primary"
              style={{ marginLeft: "auto" }}
              id="exportButton"
              disabled={loading || exporting}
              onClick={handleExport}
            >
              {exporting ? "Exporting..." : "Export to Excel"}
            </Button>
          </div>
          <Table
            columns={[
              {
                title: "Reg. Number",
                dataIndex: "regno",
                width: "10%",
              },
              {
                title: "Full Name",
                dataIndex: "name",
                width: "25%",
              },
              {
                title: "Email",
                dataIndex: "email",
                width: "20%",
              },
              {
                title: "Created At",
                dataIndex: "createdAt",
                width: "10%",
              },
              {
                title: "Status",
                dataIndex: "status",
                width: "5%",
              },
            ]}
            loading={loading}
            dataSource={playersList || []}
            onRow={(record, index) => {
              return {
                onClick: (event) => {
                  getIndividualPlayerDetails(record.regno);
                },
              };
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Spin style={{ fontSize: "xx-large", color: "blue" }} />
    </div>
  );
};
