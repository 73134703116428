import { Modal } from "antd";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    background: #8db5ff8c;
  }
`;

export const StyledModalDanger = styled(Modal)`
  .ant-modal-header {
    background: #ffa9aa;
  }
`;
