import {
  Card,
  Button,
  Spin,
  Input,
  message,
  Typography,
  Switch,
  Radio,
  Divider,
  Tooltip,
} from "antd";
import { useEffect } from "react";
import { useContext, useState } from "react";
import {
  changeUserPassword,
  login,
  updateDocumentsConfig,
} from "../api/difaOfficialApis";
import { UserContext } from "../App";
import { StyledModal } from "./styledComponents/styledModal";

export const Settings = () => {
  const {
    difaOfficial,
    documentsConfig,
    setDocumentsConfig,
    loadDocumentsConfig,
  } = useContext(UserContext);

  const [tab, setTab] = useState(0);

  const [unlocked, setUnlocked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const authenticate = async () => {
    setLoading(true);
    login(difaOfficial.email, currentPassword)
      .then(() => {
        message.success("Success! You can now enter your new password!");
        setUnlocked(true);
        setModalOpen(false);
        setCurrentPassword("");
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const changePassword = async () => {
    setLoading(true);
    changeUserPassword(difaOfficial.adminId, newPassword)
      .then(() => {
        message.success("Your password has been updated successfully!");
        setUnlocked(false);
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const saveDocumentsConfiguration = async (config) => {
    updateDocumentsConfig(config)
      .then(() => {})
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      });
  };

  const isValid = () => {
    if (newPassword === "") return false;
    if (newPassword !== confirmPassword) return false;

    return true;
  };

  const passwordModal = (
    <StyledModal
      title="Please enter your current password"
      visible={modalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      onCancel={() => setModalOpen(false)}
      cancelButtonProps={{ disabled: loading }}
      okText="Submit"
      okButtonProps={{ disabled: loading || currentPassword === "" }}
      onOk={authenticate}
    >
      <Input
        title="Password"
        placeholder="Enter password here"
        type="password"
        value={currentPassword}
        disabled={loading}
        onChange={(e) => {
          setCurrentPassword(e.target.value);
        }}
      />
    </StyledModal>
  );

  const RegistrationSettings = (
    <>
      <Card title="Required Fields">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Email</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.email}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      email: !documentsConfig.email,
                    });
                  }}
                />
              </div>
            </Card>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Phone</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.phone}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      phone: !documentsConfig.phone,
                    });
                  }}
                />
              </div>
            </Card>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Date of Birth</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.dateOfBirth}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      dateOfBirth: !documentsConfig.dateOfBirth,
                    });
                  }}
                />
              </div>
            </Card>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Blood Group</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.bloodGroup}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      bloodGroup: !documentsConfig.bloodGroup,
                    });
                  }}
                />
              </div>
            </Card>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Home Address (India)</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.homeAddress}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      homeAddress: !documentsConfig.homeAddress,
                    });
                  }}
                />
              </div>
            </Card>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Saudi Address</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.saudiAddress}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      saudiAddress: !documentsConfig.saudiAddress,
                    });
                  }}
                />
              </div>
            </Card>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Company Name</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.companyName}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      companyName: !documentsConfig.companyName,
                    });
                  }}
                />
              </div>
            </Card>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Player Photo</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.photo}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      photo: !documentsConfig.photo,
                    });
                  }}
                />
              </div>
            </Card>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Passport Number</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.passportNumber}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      passportNumber: !documentsConfig.passportNumber,
                    });
                  }}
                />
              </div>
            </Card>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Iqama Number</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.iqamaNumber}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      iqamaNumber: !documentsConfig.iqamaNumber,
                    });
                  }}
                />
              </div>
            </Card>
          </div>
        </div>
      </Card>

      <Card
        title="Required Documents"
        style={{ marginTop: "1em", marginBottom: "1em" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Passport</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.passport}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      passport: !documentsConfig.passport,
                    });
                  }}
                />
              </div>
            </Card>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Iqama</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.iqama}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      iqama: !documentsConfig.iqama,
                    });
                  }}
                />
              </div>
            </Card>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>DIFA Registration Form</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.registrationForm}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={() => {
                    setDocumentsConfig({
                      ...documentsConfig,
                      registrationForm: !documentsConfig.registrationForm,
                    });
                  }}
                />
              </div>
            </Card>
            <Card
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography>Affidavit</Typography>
                <Switch
                  checked={documentsConfig && documentsConfig.affidavit}
                  disabled={loading || !documentsConfig}
                  style={{ marginLeft: "auto" }}
                  onChange={async () => {
                    await setDocumentsConfig({
                      ...documentsConfig,
                      affidavit: !documentsConfig.affidavit,
                    });
                  }}
                />
              </div>
            </Card>
          </div>
        </div>
      </Card>
    </>
  );
  const TransferSettings = (
    <>
      <Card title="Transfer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card
              style={{
                width: "50%",
              }}
            >
              <Tooltip title="Transfer window is only open for clubs if it's enabled here!">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Typography>
                    Transfer Window (
                    {documentsConfig?.transferOpen ? "Enabled" : "Disabled"})
                  </Typography>
                  <Switch
                    checked={documentsConfig && documentsConfig.transferOpen}
                    disabled={loading || !documentsConfig}
                    style={{ marginLeft: "auto" }}
                    onChange={() => {
                      setDocumentsConfig({
                        ...documentsConfig,
                        transferOpen: !documentsConfig.transferOpen,
                      });
                    }}
                  />
                </div>
              </Tooltip>
            </Card>

            {documentsConfig?.transferOpen && (
              <Card
                style={{
                  width: "50%",
                }}
              >
                <Tooltip title="Enabling this will make uploading transfer document mandatory!">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      Transfer Document (
                      {documentsConfig?.transferDocument
                        ? "Enabled"
                        : "Disabled"}
                      )
                    </Typography>
                    <Switch
                      checked={
                        documentsConfig && documentsConfig.transferDocument
                      }
                      disabled={loading || !documentsConfig}
                      style={{ marginLeft: "auto" }}
                      onChange={() => {
                        setDocumentsConfig({
                          ...documentsConfig,
                          transferDocument: !documentsConfig.transferDocument,
                        });
                      }}
                    />
                  </div>
                </Tooltip>
              </Card>
            )}
          </div>
        </div>
      </Card>
    </>
  );

  const SettingsTabs = [RegistrationSettings, TransferSettings];

  useEffect(() => {
    saveDocumentsConfiguration({ ...documentsConfig });
  }, [documentsConfig]);

  useEffect(() => {
    loadDocumentsConfig();
  }, [difaOfficial]);

  return difaOfficial ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {passwordModal}
      <div style={{ width: "90%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              fontSize: "large",
              fontWeight: "bolder",
              marginTop: "2%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            USER SETTINGS
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <Button
              disabled={!modalOpen && unlocked}
              onClick={() => setModalOpen(true)}
              type="primary"
            >
              Change Password
            </Button>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <Card title={"Email"} style={{ width: "50%" }}>
              <div>
                <Input value={difaOfficial.email} disabled />
              </div>
            </Card>
            {unlocked ? (
              <Card title={"Password"} style={{ width: "50%" }}>
                <div style={{ marginBottom: "20px" }}>
                  <Input
                    placeholder="Enter your new password"
                    type="password"
                    value={newPassword}
                    disabled={loading}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <Input
                    placeholder="Confrim your new password by entering it again"
                    type="password"
                    value={confirmPassword}
                    disabled={loading}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div>
                  <Button
                    type="primary"
                    disabled={!isValid() || loading}
                    onClick={changePassword}
                  >
                    Submit
                  </Button>
                </div>
              </Card>
            ) : (
              <Card title={"Password"} style={{ width: "50%" }}>
                <div>
                  <Input
                    type="password"
                    value={difaOfficial.password}
                    disabled
                  />
                </div>
              </Card>
            )}
          </div>

          <Divider />

          <h1
            style={{
              fontSize: "large",
              fontWeight: "bolder",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            PLATFORM SETTINGS
          </h1>

          <div style={{ marginBottom: "20px" }}>
            <Radio.Group
              onChange={(e) => setTab(e.target.value)}
              value={tab}
              disabled={loading}
              buttonStyle="solid"
            >
              <Radio.Button value={0}>REGISTRATION</Radio.Button>
              <Radio.Button value={1}>TRANSFER</Radio.Button>
            </Radio.Group>
          </div>

          {SettingsTabs[tab]}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Spin style={{ fontSize: "xx-large", color: "blue" }} />
    </div>
  );
};
