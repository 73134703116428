import {
  FullscreenOutlined,
  LeftCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Card, message, Image, Spin, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPlayerRequestByRegisterNumber,
  SERVER_BASE_URL,
  updatePlayer,
  uploadPlayerAffidavit,
  uploadPlayerImage,
  uploadPlayerIqama,
  uploadPlayerPassport,
  uploadPlayerRegistrationForm,
} from "../api/difaOfficialApis";
import { UserContext } from "../App";
import avatar from "../assets/avatar.png";
import {
  MAX_DOCUMENT_SIZE,
  MAX_IMAGE_SIZE,
  SUPPORTED_DOCUMENT_MIMETYPES,
  SUPPORTED_IMAGE_MIMETYPES,
} from "../constants";

export const EditPlayer = () => {
  const { registrationNumber } = useParams();
  const [player, setPlayer] = useState(null);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const photoRef = useRef(null);
  const registrationFormRef = useRef(null);
  const affidavitRef = useRef(null);
  const passportRef = useRef(null);
  const iqamaRef = useRef(null);

  let navigate = useNavigate();

  const { difaOfficial } = useContext(UserContext);

  async function loadPlayer() {
    if (!difaOfficial) return;

    setLoading(true);
    getPlayerRequestByRegisterNumber(registrationNumber)
      .then((res) => {
        if (res.status === 200) {
          setPlayer(res.data.request);
        }
      })
      .catch((err) => {
        message.error("Could not connect with the server. Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  const isEmail = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValid = () => {
    if (
      player.fullName.trim() === "" ||
      player.email.trim() === "" ||
      player.phone.trim() === ""
    ) {
      message.warning("Please fill all the required fields!");
      return false;
    }

    if (player.phone.length < 5 || isNaN(player.phone)) {
      message.warning("Please enter a valid phone number!");
      return false;
    }

    if (!isEmail(player.email)) {
      message.warning("Please enter a valid email address!");
      return false;
    }

    if (player.iqama.trim() !== "" && player.iqamaNumber.trim() === "") {
      message.warning(
        "Please enter the iqama number from the uploaded iqama file!"
      );
      return false;
    }

    if (player.passport.trim() !== "" && player.passportNumber.trim() === "") {
      message.warning(
        "Please enter the passport number from the uploaded passport file!"
      );
      return false;
    }

    return true;
  };

  const onSelectPhoto = (e) => {
    const file = e.target.files[0];

    if (!file) {
      message.error("Please select a file to continue");
      return;
    }

    if (!SUPPORTED_IMAGE_MIMETYPES.includes(file.type)) {
      message.error("Please select an image file");
      return;
    }

    if (file.size > MAX_IMAGE_SIZE) {
      message.error("Only images with less than 500KB are allowed!");
      return;
    }

    setLoading(true);
    uploadPlayerImage(file, player.s3Id)
      .then((res) => {
        setPlayer({ ...player, photo: res.data.key });
      })
      .catch(() => {
        message.error("Something went wrong! Failed to save player photo.");
      })
      .finally(() => setLoading(false));
  };

  const onSelectDocument = (e) => {
    const file = e.target.files[0];

    if (!file) {
      message.error("Please select a file to continue");
      return;
    }

    if (!SUPPORTED_DOCUMENT_MIMETYPES.includes(file.type)) {
      message.error("Please select an image or PDF file");
      return;
    }

    if (file.size > MAX_DOCUMENT_SIZE) {
      message.error("Only files with less than 5MB are allowed!");
      return;
    }

    switch (e.target.id) {
      case "registrationForm":
        setLoading(true);
        uploadPlayerRegistrationForm(file, player.s3Id)
          .then((res) => {
            setPlayer({ ...player, registrationForm: res.data.key });
          })
          .catch(() => {
            message.error(
              "Something went wrong! Failed to upload player DIFA registration form."
            );
          })
          .finally(() => setLoading(false));
        break;
      case "affidavit":
        setLoading(true);
        uploadPlayerAffidavit(file, player.s3Id)
          .then((res) => {
            setPlayer({ ...player, affidavit: res.data.key });
          })
          .catch(() => {
            message.error(
              "Something went wrong! Failed to upload player affidavit."
            );
          })
          .finally(() => setLoading(false));
        break;
      case "passport":
        setLoading(true);
        uploadPlayerPassport(file, player.s3Id)
          .then((res) => {
            setPlayer({ ...player, passport: res.data.key });
          })
          .catch(() => {
            message.error(
              "Something went wrong! Failed to save player passport."
            );
          })
          .finally(() => setLoading(false));
        break;
      case "iqama":
        setLoading(true);
        uploadPlayerIqama(file, player.s3Id)
          .then((res) => {
            setPlayer({ ...player, iqama: res.data.key });
          })
          .catch(() => {
            message.error("Something went wrong! Failed to save player iqama.");
          })
          .finally(() => setLoading(false));
        break;
      default:
        break;
    }
  };

  const getSignedDocumentLink = (link) => {
    const token = localStorage.getItem("token");
    return `${SERVER_BASE_URL}/difa-official/get-player-document?token=${token}&key=${link}`;
  };

  const submit = async () => {
    if (!isValid()) return false;

    setSubmitting(true);
    setLoading(true);
    updatePlayer(player.playerId, { ...player })
      .then(() => {
        message.success("Player updated successfully!");
        navigate(`/registration-requests/${registrationNumber}`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          const msg = err.response.data.message || "Conflict error!";
          message.error(msg);
        } else message.error("Something went wrong! Please try Again.");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    loadPlayer();
  }, [difaOfficial]);

  return player ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "70%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            gap: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              paddingBottom: "18px",
              flex: 0.01,
            }}
          >
            <Button
              icon={<LeftCircleOutlined />}
              style={{
                marginTop: "20px",
                border: "2px solid black",
                backgroundColor: "transparent",
                color: "black",
              }}
              onClick={() => {
                navigate(`/registration-requests/${registrationNumber}`, {
                  replace: true,
                });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              flex: 0.99,
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                marginTop: "2%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              EDIT PLAYER
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "5px",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                disabled={loading || submitting}
                onClick={submit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            gap: "2em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                width: "100%",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      gap: "1em",
                      marginBottom: "25px",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <Image
                        src={
                          player.photo
                            ? SERVER_BASE_URL +
                              `/get-player-image?key=${player.photo}`
                            : avatar
                        }
                        width={"100%"}
                      />
                    </div>
                  </div>
                  <Button
                    disabled={loading}
                    onClick={() => {
                      photoRef.current.click();
                    }}
                    type="dashed"
                  >
                    <input
                      type="file"
                      id="photo"
                      accept="image/*"
                      ref={photoRef}
                      onChange={onSelectPhoto}
                      style={{ display: "none" }}
                    />
                    {player.photo ? "Change Photo" : "Upload Photo"}
                  </Button>
                </Card>
              </div>

              <Card title={"Registration Number"} style={{ width: "100%" }}>
                <div>
                  <Input value={player.registrationNumber} disabled />
                </div>
              </Card>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card
                  title={
                    <span>
                      <label style={{ color: "red" }}>{"* "}</label>
                      Player Name (As Mentioned in Passport/Iqama)
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <Input
                    disabled={loading}
                    value={player.fullName}
                    onChange={(e) => {
                      setPlayer({ ...player, fullName: e.target.value });
                    }}
                    placeholder="Enter Player Name"
                  />{" "}
                </Card>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card
                  title={
                    <span>
                      <label style={{ color: "red" }}>{"* "}</label>
                      Email
                    </span>
                  }
                  style={{ width: "50%" }}
                >
                  <Input
                    disabled={loading}
                    value={player.email}
                    placeholder="johndoe@domain.com"
                    onChange={(e) => {
                      setPlayer({ ...player, email: e.target.value });
                    }}
                  />
                </Card>
                <Card
                  title={
                    <span>
                      <label style={{ color: "red" }}>{"* "}</label>
                      Mobile Number
                    </span>
                  }
                  style={{ width: "50%" }}
                >
                  <Input
                    disabled={loading}
                    value={player.phone}
                    placeholder="Please enter player's mobile number..."
                    onChange={(e) => {
                      setPlayer({ ...player, phone: e.target.value });
                    }}
                  />
                </Card>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Date of Birth"} style={{ width: "50%" }}>
                  <Input
                    disabled={loading}
                    value={player.dateOfBirth}
                    onChange={(e) => {
                      setPlayer({ ...player, dateOfBirth: e.target.value });
                    }}
                    maxLength={10}
                    placeholder="Example: 31/12/1989"
                  />
                </Card>
                <Card title={"Blood Group"} style={{ width: "50%" }}>
                  <Select
                    defaultValue="None"
                    value={player.bloodGroup}
                    style={{ width: 120 }}
                    onChange={(bloodGroup) => {
                      setPlayer({ ...player, bloodGroup });
                    }}
                  >
                    <Select.Option value="">None</Select.Option>
                    <Select.Option value="A+">A+</Select.Option>
                    <Select.Option value="A-">A-</Select.Option>
                    <Select.Option value="B+">B+</Select.Option>
                    <Select.Option value="B-">B-</Select.Option>
                    <Select.Option value="AB+">AB+</Select.Option>
                    <Select.Option value="AB-">AB-</Select.Option>
                    <Select.Option value="O+">O+</Select.Option>
                    <Select.Option value="O-">O-</Select.Option>
                  </Select>
                </Card>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card title={"Home Address (India)"} style={{ width: "50%" }}>
                  <TextArea
                    rows={4}
                    disabled={loading}
                    value={player.homeAddress}
                    onChange={(e) => {
                      setPlayer({ ...player, homeAddress: e.target.value });
                    }}
                    placeholder="Enter the player's address in India"
                  />
                </Card>
                <Card title={"Saudi Address"} style={{ width: "50%" }}>
                  <TextArea
                    rows={4}
                    disabled={loading}
                    value={player.saudiAddress}
                    onChange={(e) => {
                      setPlayer({ ...player, saudiAddress: e.target.value });
                    }}
                    placeholder="Enter the player's residential address in Saudi Arabia"
                  />
                </Card>
              </div>
              <Card title="Company Name" style={{ width: "100%" }}>
                <Input
                  disabled={loading}
                  value={player.companyName}
                  onChange={(e) => {
                    setPlayer({ ...player, companyName: e.target.value });
                  }}
                  placeholder="Enter the player's employer / company name"
                />
              </Card>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1em" }}
              >
                <Card
                  title={
                    <div>
                      {player.passport && (
                        <label style={{ color: "red" }}>{"* "}</label>
                      )}
                      Passport Number
                    </div>
                  }
                  style={{ width: "50%" }}
                >
                  <Input
                    disabled={loading}
                    value={player.passportNumber}
                    onChange={(e) => {
                      setPlayer({ ...player, passportNumber: e.target.value });
                    }}
                    placeholder="Enter Player Passport Number"
                  />
                </Card>
                <Card
                  title={
                    <div>
                      {player.iqama && (
                        <label style={{ color: "red" }}>{"* "}</label>
                      )}
                      Iqama Number
                    </div>
                  }
                  style={{ width: "50%" }}
                >
                  <Input
                    disabled={loading}
                    value={player.iqamaNumber}
                    onChange={(e) => {
                      setPlayer({ ...player, iqamaNumber: e.target.value });
                    }}
                    placeholder="Enter Player Iqama Number"
                  />
                </Card>
              </div>
              <Card
                title={"Player Documents"}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        DIFA Registration Form
                        {!player.registrationForm && " - No File Uploaded"}
                      </div>
                      <div style={{ marginLeft: "auto" }}>
                        {player.registrationForm && (
                          <a
                            className="link"
                            href={
                              player.registrationForm
                                ? getSignedDocumentLink(player.registrationForm)
                                : null
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              type="primary"
                              icon={<FullscreenOutlined />}
                              disabled={loading}
                            >
                              View
                            </Button>
                          </a>
                        )}
                        <Button
                          style={{ marginLeft: "10px" }}
                          type="dashed"
                          onClick={() => {
                            registrationFormRef.current.click();
                          }}
                          disabled={loading}
                        >
                          <input
                            type="file"
                            id="registrationForm"
                            accept="image/*,application/pdf"
                            ref={registrationFormRef}
                            onChange={onSelectDocument}
                            style={{ display: "none" }}
                          />
                          {player.registrationForm ? "Change" : "Upload"}
                        </Button>
                      </div>
                    </div>
                  </Card>

                  <Card>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        Affidavit
                        {!player.affidavit && " - No File Uploaded"}
                      </div>
                      <div style={{ marginLeft: "auto" }}>
                        {player.affidavit && (
                          <a
                            className="link"
                            href={
                              player.affidavit
                                ? getSignedDocumentLink(player.affidavit)
                                : null
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              type="primary"
                              icon={<FullscreenOutlined />}
                              disabled={loading}
                            >
                              View
                            </Button>
                          </a>
                        )}
                        <Button
                          style={{ marginLeft: "10px" }}
                          type="dashed"
                          onClick={() => {
                            affidavitRef.current.click();
                          }}
                          disabled={loading}
                        >
                          <input
                            type="file"
                            id="affidavit"
                            accept="image/*,application/pdf"
                            ref={affidavitRef}
                            onChange={onSelectDocument}
                            style={{ display: "none" }}
                          />
                          {player.affidavit ? "Change" : "Upload"}
                        </Button>
                      </div>
                    </div>
                  </Card>

                  <Card>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        Passport
                        {!player.passport && " - No File Uploaded"}
                      </div>
                      <div style={{ marginLeft: "auto" }}>
                        {player.passport && (
                          <a
                            className="link"
                            href={
                              player.passport
                                ? getSignedDocumentLink(player.passport)
                                : null
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              type="primary"
                              icon={<FullscreenOutlined />}
                              disabled={loading}
                            >
                              View
                            </Button>
                          </a>
                        )}
                        <Button
                          style={{ marginLeft: "10px" }}
                          type="dashed"
                          onClick={() => {
                            passportRef.current.click();
                          }}
                          disabled={loading}
                        >
                          <input
                            type="file"
                            id="passport"
                            accept="image/*,application/pdf"
                            ref={passportRef}
                            onChange={onSelectDocument}
                            style={{ display: "none" }}
                          />
                          {player.passport ? "Change" : "Upload"}
                        </Button>
                      </div>
                    </div>
                  </Card>

                  <Card>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        Iqama / Saudi Visa Copy
                        {!player.iqama && " - No File Uploaded"}
                      </div>
                      <div style={{ marginLeft: "auto" }}>
                        {player.iqama && (
                          <a
                            className="link"
                            href={
                              player.iqama
                                ? getSignedDocumentLink(player.iqama)
                                : null
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              type="primary"
                              icon={<FullscreenOutlined />}
                              disabled={loading}
                            >
                              View
                            </Button>
                          </a>
                        )}
                        <Button
                          style={{ marginLeft: "10px" }}
                          type="dashed"
                          onClick={() => {
                            iqamaRef.current.click();
                          }}
                          disabled={loading}
                        >
                          <input
                            type="file"
                            id="iqama"
                            accept="image/*,application/pdf"
                            ref={iqamaRef}
                            onChange={onSelectDocument}
                            style={{ display: "none" }}
                          />
                          {player.iqama ? "Change" : "Upload"}
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Spin style={{ fontSize: "xx-large", color: "blue" }} />
    </div>
  );
};
