export const lightBorder = "#e5e5e5";
export const focusBorder = "#9effb8";

export const STATUSES = {
  pending: 0,
  onHold: 1,
  approved: 2,
  fullyApproved: 3,
};

export const TRANSFER_STATUSES = {
  unattended: 0,
  onHold: 1,
  approved: 2,
  fullyApproved: 3,
};

export const LIMIT = 100;

export const MAX_IMAGE_SIZE = 500 * 1024;

export const SUPPORTED_IMAGE_MIMETYPES = [
	'image/jpeg',
	'image/jpg',
	'image/png',
];

export const MAX_DOCUMENT_SIZE = 5 * 1000 * 1024;

export const SUPPORTED_DOCUMENT_MIMETYPES = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'application/pdf',
];
