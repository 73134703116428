import { LoginForm } from "../components/loginForm"
import logo from "../assets/logo-dark.png";
import loginBG from "../assets/loginBG.jpg";

export const Login = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "black",
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${loginBG})`,
        backgroundSize: "100%",
      }}
    >
      <img
        src={logo}
        alt="DIFA KSA Logo"
        style={{ position: "absolute", top: "15px", left: "10px" }}
      />
      <LoginForm />
    </div>
  );
};