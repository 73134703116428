import { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const PersistentLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  let token;
  let difaOfficial;

  useEffect(() => {
    token = localStorage.getItem("token");
    difaOfficial = localStorage.getItem("difaOfficial");

    if (!token && !difaOfficial) {
      setIsLoading(true);
      setAuth(null);
      navigate("/login", { state: location, replace: true });
    } else {
      setAuth({ token, difaOfficial });
      setIsLoading(false);
    }
  }, []);

  return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>;
};